import React from 'react';
import styled, { css } from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import { ifProp, switchProp } from 'styled-tools';
import ReadyToTrade from 'components/ReadyToTrade';
import Layout from 'components/Layout';
import SectionsWrapper from 'components/SectionsWrapper';
import { useLayoutContext } from 'context/LayoutContext';
import Section from 'components/Section';
import Hero from 'components/Hero';
import TastyButton from 'components/TastyButton';
import aboutUsHero from 'images/image-about-us-hero.png';
import whoWeAre from 'images/image-leadership-team.png';
import timeline81to99 from 'images/image-81-99.svg';
import timeline99to09 from 'images/image-99-09.svg';
import timeline11to17 from 'images/image-11-17.svg';
import icon81Profile from 'images/icon-81-profile.svg';
import icon87Profile from 'images/icon-87-profile.svg';
import icon04Profile from 'images/icon-04-profile.svg';
import icon99Profile from 'images/icon-99-profile.svg';
import icon95Ring from 'images/icon-95-ring.svg';
import icon99Ring from 'images/icon-99-ring.svg';
import icon09Ring from 'images/icon-09-ring.svg';
import icon17Ring from 'images/icon-17-ring.svg';
import icon13Badge from 'images/icon-13-badge.svg';
import icon14Badge from 'images/icon-14-badge.svg';
import icon16Star from 'images/icon-16-star.svg';
import iconStory from 'images/icon-story.svg';
import ExternalLink from 'components/ExternalLink';
import externalLinks from 'utils/externalLinks';
import SEO from 'components/SEO';
import TitleHeader from 'components/TitleHeader';

const AboutUsWrapper = styled(SectionsWrapper)`
  &.container {
    padding-left: 0;
    padding-right: 0;
  }
`;

const AboutUsSection = styled(Section)`
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  ${ifProp('narrowPadding', css`
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  `)}

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const WhoWeAreSection = styled(Section)`
  background: ${props => props.theme.colors.black};
  padding: 2rem 0 0;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 3rem 0 0;
  }

  h2 {
    color: ${props => props.theme.colors.white};
    margin-bottom: 1em;
    padding-left: 0.5em;
    font-size: 1.625rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding-left: 0.9em;
      font-size: 2.5rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      padding-left: 4em;
    }
  }

  img {
    max-width: 100%;
  }
`;

const StyledRow = styled(Row)`
  justify-content: center;
`;

const WhoWeAreDetailsSection = styled(Section)`
  background: ${props => props.theme.colors.lighterGray};
  padding: 2rem 1.25rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 2em;
  }

  .col-sm-12 {
    margin-bottom: 1rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-bottom: 0;
    }
  }

  .container-fluid {
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding: 0;
    }

    table {
      width: 100%;
      font-family: 'Open Sans';
      font-size: 0.9em;

      td {
        width: 66%;
        vertical-align: top;
        padding-bottom: 0.5em;
      }

      td:first-child {
        padding-bottom: 0;
        width: 33%;
      }
    }

    p {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      font-size: 0.9em;
      width: 100%;

      label,
      span {
        flex: 0 1 auto;
      }

      span {
        text-align: right;

        &:only-child {
          align-self: flex-end;
        }
      }
    }

    h5 {
      font-family: 'Open Sans';
      font-weight: bold;
    }

    label.now {
      color: ${props => props.theme.colors.primaryRed};
    }

    label.then {
      color: ${props => props.theme.colors.gray};
    }
  }
`;

const Timeline = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    span {
      flex: 0 1 auto;
      padding-right: 1em;

      img {
        display: block;
        width: 40px;
      }
    }

    span:last-child {
      text-align: right;
      padding-right: 0;
    }
  }
`;

const TimelineSection = styled(Section)`
  padding: 0 0.3125rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 2em;
  }

  &&& {
    a {
      color: ${props => props.theme.colors.primaryRed};
      text-decoration: none;

      &:hover {
        color: ${props => props.theme.colors.gray};
        text-decoration: none;
      }
    }
  }

  img {
    &.timespan {
      width: 145px;
      margin-bottom: 1em;
    }

    &.your-story {
      width: 40px;
    }
  }

  h5 {
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 1em;
  }

  .row > div {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .row > div:first-child {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      border-right: 1px dotted ${props => props.theme.colors.grayscaleGreen};
    }

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding-bottom: 0;
    }
  }

  .row > div:last-child {
    text-align: right;

    span {
      font-family: 'Open Sans';
      font-size: 0.75em;
    }

    ${TastyButton} {
      display: block;
      font-size: 1.5em;
      width: 100%;
      margin-top: 1em;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      color: ${props => props.theme.colors.white};

      &:hover {
        background-color: ${props => props.theme.colors.primaryBlack};
      }
    }
  }

  ${switchProp('color', {
    light: css`
      background-color: ${props => props.theme.colors.primaryOffWhite};

      h5 {
        color: ${props => props.theme.colors.darkGrayscaleGreen};
      }
    `,
    medium: css`
      background-color: ${props => props.theme.colors.darkGrayscaleGreen};

      h5 {
        color: ${props => props.theme.colors.primaryOffWhite};
      }

      li {
        color: ${props => props.theme.colors.white};
      }
    `,
    dark: css`
      background-color: ${props => props.theme.colors.primaryBlack};
      color: ${props => props.theme.colors.white};

      h5 {
        color: ${props => props.theme.colors.darkGrayscaleGreen};
      }
    `,
    darkest: css`
      background-color: ${props => props.theme.colors.black};
      color: ${props => props.theme.colors.white};

      h5 {
        color: ${props => props.theme.colors.white};
      }

      .row > div:last-child {
        text-align: left;
      }
    `,
  })}
`;

function AboutUsPage({ showPromoBar }) {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <Layout>
      <SEO
        title='About Us'
        description='The founders of tastytrade are Tom Sosnoff, Scott Sheridan, and Kristi Ross - the same team that started tastylive.'
        canonicalHref='https://tastytrade.com/about-us'
      />
      <Hero
        heroImage={aboutUsHero}
        $showAlert={showAlert}
        showPromoBar={showPromoBar}
      >
        <h1>About Us</h1>
        <h2>Freedom to trade confidently</h2>
      </Hero>
      <AboutUsWrapper>
        <AboutUsSection withPadding>
          <p>
            We believe in do-it-yourself investing. We think controlling your own
            money can be more rewarding than relying on money managers or
            robo-advisors who put your portfolio on autopilot.
          </p>
          <p>
            That’s why tastytrade gives you every edge possible with the
            technology, rates, and support that help you succeed more easily on
            your own. We’re as serious about your money as you are, and we work
            for you who are brave enough to take your finances into your own
            hands.
          </p>
        </AboutUsSection>
        <WhoWeAreSection>
          <TitleHeader>Who We Are</TitleHeader>
          <img src={whoWeAre} alt="Who We Are" />
        </WhoWeAreSection>
        <WhoWeAreDetailsSection>
          <Container fluid>
            <StyledRow>
              <Col sm={12} md={3}>
                <h5>Scott Sheridan</h5>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <label className="now">Now</label>
                      </td>
                      <td>CEO, tastytrade, Inc.</td>
                    </tr>
                    <tr>
                      <td>
                        <label className="then">Then</label>
                      </td>
                      <td>Co-founder, thinkorswim</td>
                    </tr>
                    <tr>
                      <td />
                      <td>Co-founder, Sosnoff Sheridan Corporation</td>
                    </tr>
                    <tr>
                      <td />
                      <td>CBOE Floor Trader</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
              <Col sm={12} md={3}>
                <h5>Tom Sosnoff</h5>
                <table>
                  <tbody>
                  <tr>
                    <td>
                      <label className="now">Now</label>
                    </td>
                    <td>CEO, tastylive, Inc.</td>
                  </tr>
                  <tr>
                    <td>
                      <label className="then">Then</label>
                    </td>
                    <td>Co-founder, thinkorswim</td>
                  </tr>
                  <tr>
                    <td />
                    <td>Co-founder, Sosnoff Sheridan Corporation</td>
                  </tr>
                  <tr>
                    <td />
                    <td>CBOE Floor Trader</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col sm={12} md={3}>
                <h5>JJ Kinahan</h5>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <label className="now">Now</label>
                      </td>
                      <td>CEO, IG North America</td>
                    </tr>
                    <tr>
                      <td>
                        <label className="then">Then</label>
                      </td>
                      <td>VP & Chief Market Strategist, tastytrade</td>
                    </tr>
                    <tr>
                      <td />
                      <td>CBOE Floor Trader</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </StyledRow>
          </Container>
        </WhoWeAreDetailsSection>
        <AboutUsSection narrowPadding>
          <p>
            We’ve always done what others won’t even try and gone where no other
            trading firms would go before. We’ve seen it all - market crashes,
            flash crashes - and our customers stick with us because they trust us.
            We’re tough, and we’re ridiculously transparent in a way you just
            don’t see in the financial industry.
          </p>
        </AboutUsSection>
        <ReadyToTrade />
        <TimelineSection color="light">
          <Container fluid>
            <Row>
              <Col sm={12} md={8}>
                <h5>We traded at the Chicago Board Options Exchange</h5>
                <p>
                  We were forged in the fires of physical floor trading. In the
                  pit we learned how to be smart, creative, and resilient with
                  finance. We quickly learned that we look at the market in a
                  different way. Once we understood the value in leveraging
                  options to sell premium, we needed a new space that would allow
                  us to trade more boldly and efficiently.
                </p>
              </Col>
              <Col sm={12} md={4}>
                <img
                  className="timespan"
                  src={timeline81to99}
                  alt="1981 to 1999"
                />
                <Timeline>
                  <li>
                    <span>
                      <img src={icon81Profile} alt="81" />
                    </span>
                    <span>Tom Sosnoff starts trading at the CBOE</span>
                  </li>
                  <li>
                    <span>
                      <img src={icon87Profile} alt="87" />
                    </span>
                    <span>Scott Sheridan starts trading at the CBOE</span>
                  </li>
                  <li>
                    <span>
                      <img src={icon95Ring} alt="95" />
                    </span>
                    <span>
                      Tom &amp; Scott found the Sosnoff Sheridan Corporation
                    </span>
                  </li>
                </Timeline>
              </Col>
            </Row>
          </Container>
        </TimelineSection>
        <TimelineSection color="medium">
          <Container fluid>
            <Row>
              <Col sm={12} md={8}>
                <h5>
                  We helped pioneer options trading technology for the retail
                  trader
                </h5>
                <p>
                  There was no brokerage or software to help us trade how we knew
                  we could. So we built a firm with the services we needed and a
                  platform with the features we wanted to do it our way. We even
                  traveled the world to share our success and to show people of
                  all types that they could trade like us and win at it. After
                  being made an attractive offer to sell the brokerage firm, there
                  was much contemplation about the decision. Ultimately, it was an
                  offer we couldn’t refuse. After all, we’re traders...
                </p>
              </Col>
              <Col sm={12} md={4}>
                <img
                  className="timespan"
                  src={timeline99to09}
                  alt="1999 to 2009"
                />
                <Timeline>
                  <li>
                    <span>
                      <img src={icon99Ring} alt="99" />
                    </span>
                    <span>Tom &amp; Scott found thinkorswim</span>
                  </li>
                  <li>
                    <span>
                      <img src={icon99Profile} alt="99" />
                    </span>
                    <span>Linwood Ma joins thinkorswim Group as CTO</span>
                  </li>
                  <li>
                    <span>
                      <img src={icon04Profile} alt="04" />
                    </span>
                    <span>Kristi Ross joins thinkorswim Group as CFO</span>
                  </li>
                  <li>
                    <span>
                      <img src={icon09Ring} alt="09" />
                    </span>
                    <span>thinkorswim acquired for $750M</span>
                  </li>
                </Timeline>
              </Col>
            </Row>
          </Container>
        </TimelineSection>
        <TimelineSection color="dark">
          <Container fluid>
            <Row>
              <Col sm={12} md={8}>
                <h5>
                  tasty<em>live</em> was formed to change how you talk about finance
                </h5>
                <p>
                  After selling our first brokerage firm, we still wanted to help
                  retail traders realize their potential for success. Active
                  traders made it clear that there was nowhere to go for sharp,
                  fun, and actionable financial content. Our affiliate,{' '}
                  <ExternalLink href={externalLinks.tastylive}>tasty<em>live</em></ExternalLink>,
                  was launched as the first live network for active
                  retail options traders. It is now an irreplaceable resource for
                  both experienced and new independent traders.
                </p>
              </Col>
              <Col sm={12} md={4}>
                <img
                  className="timespan"
                  src={timeline11to17}
                  alt="2011 to 2017"
                />
                <Timeline>
                  <li>
                    <span>
                      <img src={icon13Badge} alt="13" />
                    </span>
                    <span>
                      tasty<em>live</em> wins Chicago Innovation Awards People’s Choice
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={icon14Badge} alt="14" />
                    </span>
                    <span>
                      Tom and Kristi win Ernst &amp; Young Entrepreneur of the
                      Year
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={icon16Star} alt="16" />
                    </span>
                    <span>
                      tastyworks is approved as a Broker-Dealer by FINRA/NFA
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={icon16Star} alt="16" />
                    </span>
                    <span>Kristi Ross wins Top Women in Tech Award</span>
                  </li>
                  <li>
                    <span>
                      <img src={icon17Ring} alt="17" />
                    </span>
                    <span>tastyworks launches to the public</span>
                  </li>
                </Timeline>
              </Col>
            </Row>
          </Container>
        </TimelineSection>
        <TimelineSection color="darkest">
          <Container fluid>
            <Row>
              <Col sm={12} md={8}>
                <h5>
                  Now we're building a new kind of brokerage for you, the active
                  trader
                </h5>
                <p>
                  The market can be a rough and random road to travel. Your
                  brokerage is the last thing that should get in your way. We
                  believe the firm itself is a crucial factor in your success. Our
                  platform gives you the technology, tasty<em>live</em> gives you the
                  know-how, and now our business structure gives you the
                  environment you need to maximize profits and reward yourself
                  more deeply than you ever thought possible.
                </p>
              </Col>
              <Col sm={12} md={4}>
                <span>
                  <img
                    className="your-story"
                    src={iconStory}
                    alt="People"
                  />
                  Now you become part of the story...
                </span>
                <TastyButton primary footer href={externalLinks.openAnAccount}>
                  Open an Account
                </TastyButton>
              </Col>
            </Row>
          </Container>
        </TimelineSection>
      </AboutUsWrapper>
    </Layout>
  );
};

export default AboutUsPage;
